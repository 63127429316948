<template>
  <div class="v-home">
    <TheHeading level="h1">Fag</TheHeading>
    <div class="v-home__section" v-for="section in sections" :key="section.id">
      <TheHeading>{{ section.name }}</TheHeading>
      <div
        v-if="section.childrens && section.childrens.length"
        class="v-home__section-wrapper"
      >
        <Card
          v-for="child in section.childrens"
          :key="child.id"
          :path="`/fag/${section.slug}/${child.slug}`"
          :title="child.name"
          :description="child.description || '&nbsp'"
          :image-id="child.cover"
          type="home"
          class="v-home__section-card"
          :disabled="!child.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import Card from '@/components/Card'
import TheHeading from '@/components/TheHeading'

export default {
  name: 'Home',

  components: {
    Card,
    TheHeading,
  },

  setup() {
    const store = useStore()
    const { locale } = useI18n()
    const fag = computed(() =>
      store.getters['content/getStructuresBySlugPath']('fag', true),
    )
    const sections = computed(() => {
      return store.getters['content/getStructuresChildrens'](
        fag.value.id,
        'DIRECTORY',
        true,
      ).map((child) => {
        child.childrens = store.getters['content/getStructuresChildrens'](
          child.id,
          'DIRECTORY',
          true,
        )
        return child
      })
    })

    const loadContent = async () => {
      await store.dispatch('content/setStructure', 'fag')
      await store.dispatch('content/setStructuresChildren', {
        slugPath: 'fag',
        query: { limit: 100, filter: `{"type": "DIRECTORY"}` },
      })
      sections.value.forEach((s) => {
        store.dispatch('content/setStructuresChildren', {
          slugPath: `fag/${s.slug}`,
          query: { limit: 100, filter: `{"type": "DIRECTORY"}` },
        })
      })
    }

    onBeforeMount(() => {
      loadContent()
    })

    watch(locale, () => {
      loadContent()
    })

    return {
      sections,
    }
  },
}
</script>

<style lang="scss">
.v-home__section-wrapper {
  display: flex;
  overflow: auto;
  margin: 2rem -1.125rem;

  @include bp(large) {
    flex-wrap: wrap;
  }
}

.v-home__section-card {
  flex: 0 0 auto;
  margin: 0 1.125rem 2.375rem;
}
</style>
